<template>
  <el-form :model="cancerregistrySettings" size="default">
    <el-row>
      <el-col :span="12">
        <el-form-item>
          <h6>Absender ID</h6>
          <el-input v-model.trim="cancerregistrySettings.senderId" placeholder="Absender ID"/>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-form-item>
          <h6>Software ID</h6>
          <el-input v-model.trim="cancerregistrySettings.softwareId" placeholder="Software ID"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item>
          <h6>Ansprechpartner</h6>
          <el-input v-model.trim="cancerregistrySettings.contactPerson" placeholder="Ansprechpartner"/>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-form-item>
          <h6>Anschrift</h6>
          <el-input v-model.trim="cancerregistrySettings.address" placeholder="Anschrift"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item>
          <h6>Telefon</h6>
          <el-input v-model.trim="cancerregistrySettings.telephone" placeholder="Telefon"/>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-form-item>
          <h6>E-Mail</h6>
          <el-input v-model.trim="cancerregistrySettings.email" placeholder="Email"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item>
          <h6>ICD Version</h6>
          <el-select v-model="cancerregistrySettings.icdVersion" class="w-100">
            <el-option v-for="version of ICDVersions" :key="version" :label="version" :value="version"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-form-item>
          <h6>CTCAE Version</h6>
          <el-input v-model.trim="cancerregistrySettings.ctcaeVersion" placeholder="CTCAE Version"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="primary" class="d-block ml-auto" @click="saveSettings">Speichern</el-button>
  </el-form>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  async mounted() {
    const data = (await axios.get("/cancerregistry/preferences")).data
    this.cancerregistrySettings = { ...data };
    await this.getICDVersions()
  },
  data() {
    return {
      cancerregistrySettings: {
        senderId: null,
        softwareId: null,
        contactPerson: null,
        address: null,
        telephone: null,
        email: null,
        icdVersion: null,
        ctcaeVersion: null
      }
    }
  },
  computed: {
    ...mapGetters('cancerregistry', ['ICDVersions'])
  },
  methods: {
    ...mapActions('cancerregistry', ['getICDVersions']),
    saveSettings() {
      axios.put("/cancerregistry/preferences", this.cancerregistrySettings)
    }
  }
}
</script>
